import { Result } from 'antd'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <Result
      status="404"
      subTitle={<FormattedMessage id="page.404.sorry" />}
      title="404"
    />
  </Layout>
)

export default NotFoundPage
